@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');

body {
    margin: 0;
    font-family: 'Inter', sans-serif;
    background: #1C2025;
    color: #EDEDED;
}

.finbotix-signup {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

/* Header */
.finbotix-signup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background: #1C2025;
    border-bottom: 1px solid #2A2F38;
}

.finbotix-logo {
    font-size: 1.5rem;
    font-weight: 700;
    color: #00AD9F;
}

.finbotix-back-link {
    text-decoration: none;
    color: #3A8CFF;
    font-weight: 500;
    transition: color 0.2s;
}

.finbotix-back-link:hover {
    color: #2d78e2;
}

/* Main Content */
.signup-content {
    padding: 60px 20px;
    text-align: center;
    flex: 1;
}

.signup-title {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 10px;
    color: #EDEDED;
}

.signup-subtitle {
    font-size: 1.1rem;
    color: #CFCFCF;
    margin-bottom: 40px;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
}

.plan-card {
    background: #252A30;
    max-width: 400px;
    margin: 0 auto;
    border-radius: 8px;
    padding: 30px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
    text-align: left;
}

.plan-card .plan-name {
    font-size: 1.5rem;
    font-weight: 700;
    color: #3A8CFF;
    margin-bottom: 5px;
}

.plan-card .plan-price {
    font-size: 1.2rem;
    font-weight: 600;
    color: #00AD9F;
    margin-bottom: 20px;
}

.plan-features {
    list-style: none;
    padding: 0;
    margin-bottom: 30px;
}

.plan-features li {
    font-size: 0.95rem;
    color: #CFCFCF;
    margin-bottom: 10px;
    padding-left: 20px;
    position: relative;
}

.plan-features li::before {
    content: "•";
    color: #00AD9F;
    position: absolute;
    left: 0;
    font-size: 1.2rem;
    line-height: 0.95rem;
    top: -2px;
}

.paypal-button {
    background: #00AD9F;
    color: #1C2025;
    padding: 12px 24px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    width: 100%;
    margin-bottom: 15px;
    transition: background 0.3s, transform 0.2s;
}

.paypal-button:hover {
    background: #009381;
    transform: translateY(-1px);
}

.plan-note {
    font-size: 0.85rem;
    color: #777777;
    text-align: center;
}

/* Footer */
.finbotix-signup-footer {
    background: #1C2025;
    text-align: center;
    padding: 20px;
    border-top: 1px solid #2A2F38;
    margin-top: auto;
}

.finbotix-signup-footer p {
    margin: 5px 0;
    font-size: 0.85rem;
    color: #CFCFCF;
}
