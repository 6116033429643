@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');

body {
    margin: 0;
    font-family: 'Inter', sans-serif;
    background: #1C2025;
    color: #EDEDED;
}

.finbotix-main {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

/* Header */
.finbotix-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background: #1C2025;
    border-bottom: 1px solid #2A2F38;
}

.finbotix-logo {
    font-size: 1.5rem;
    font-weight: 700;
    color: #00AD9F; /* Brand accent */
}

.finbotix-nav .nav-link {
    margin-left: 20px;
    text-decoration: none;
    color: #EDEDED;
    font-weight: 500;
    transition: color 0.2s ease-in;
}

.finbotix-nav .nav-link:hover {
    color: #3A8CFF; /* Secondary accent for hover */
}

.nav-cta {
    font-weight: 600;
    color: #3A8CFF;
}

/* Hero Section */
.finbotix-hero {
    display: flex;
    align-items: center;
    padding: 80px 50px;
    background: linear-gradient(135deg, #1F242D 0%, #2A2F38 100%);
    justify-content: space-between;
}

.hero-content {
    max-width: 600px;
}

.hero-title {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 20px;
    line-height: 1.2;
    color: #EDEDED;
}

.hero-subtitle {
    font-size: 1.1rem;
    margin-bottom: 30px;
    color: #CFCFCF;
}

.hero-actions {
    display: flex;
    align-items: center;
}

.btn-primary {
    background: #00AD9F;
    color: #1C2025;
    padding: 12px 24px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    margin-right: 20px;
    font-size: 1rem;
    font-weight: 600;
    transition: background 0.3s, transform 0.2s;
}

.btn-primary:hover {
    background: #009381;
    transform: translateY(-1px);
}

.hero-actions .hero-link {
    color: #3A8CFF;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 500;
    border-bottom: 1px solid transparent;
    transition: border-bottom 0.2s ease;
}

.hero-actions .hero-link:hover {
    border-bottom: 1px solid #3A8CFF;
}

/* Features */
.finbotix-features {
    padding: 60px 20px;
    text-align: center;
    background: #1C2025;
}

.finbotix-features h2 {
    font-size: 2rem;
    margin-bottom: 40px;
    font-weight: 700;
    color: #EDEDED;
}

.features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    gap: 30px;
    max-width: 900px;
    margin: 0 auto;
}

.feature-card {
    background: #252A30;
    padding: 30px;
    border-radius: 8px;
    transition: box-shadow 0.3s, transform 0.2s;
    text-align: left;
}

.feature-card:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
    transform: translateY(-2px);
}

.feature-icon {
    font-size: 2rem;
    margin-bottom: 15px;
}

.feature-card h3 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 10px;
    color: #3A8CFF;
}

.feature-card p {
    font-size: 0.95rem;
    color: #CFCFCF;
}

/* Testimonial */
.finbotix-testimonial {
    padding: 60px 20px;
    background: #2A2F38;
    text-align: center;
}

.finbotix-testimonial blockquote {
    font-style: italic;
    font-size: 1.4rem;
    max-width: 600px;
    margin: 0 auto;
    line-height: 1.4;
    color: #EDEDED;
}

.finbotix-testimonial footer {
    margin-top: 15px;
    font-size: 1rem;
    color: #CFCFCF;
}

/* Footer */
.finbotix-footer {
    background: #1C2025;
    text-align: center;
    padding: 20px;
    margin-top: auto;
    border-top: 1px solid #2A2F38;
}

.finbotix-footer p {
    margin: 5px 0;
    font-size: 0.85rem;
    color: #CFCFCF;
}

.finbotix-footer a {
    color: #3A8CFF;
    text-decoration: none;
}

.finbotix-footer a:hover {
    text-decoration: underline;
}

.hero-illustration img {
    width: 400px;
    height: 200px;
}
